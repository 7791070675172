import {
  SET_MESSAGE,
  REQUEST_UPSELL,
  RECEIVE_UPSELL,
  RECEIVE_UPSELL_FAILED,
  REACTIVATE_CARD_SUCCESS,
  // START_LOADING,
  // STOP_LOADING,
  REQUEST_NEW_SERVICE_PORTFOLIOS,
  RECEIVE_NEW_SERVICE_PORTFOLIOS,
  REQUEST_NEW_SERVICE_OPTIONS,
  REQUEST_NEW_SERVICE_OPTIONS_FAILED,
  RECEIVE_NEW_SERVICE_OPTIONS,
  REQUEST_UPSELL_OPTIONS,
  REQUEST_UPSELL_OPTIONS_FAILED,
  RECEIVE_UPSELL_OPTIONS,
  SELECT_PORTFOLIO,
  SELECT_PACKAGE_CORE,
  SELECT_INSTALLATION_NEW,
  SELECT_INSTALLATION_UPSELL,
  REQUEST_SUBMIT_STEP_1,
  RECEIVE_SUBMIT_STEP_1,
  RECEIVE_SUBMIT_STEP_1_FAILED,
  REQUEST_SUBMIT_STEP_2,
  RECEIVE_SUBMIT_STEP_2,
  RECEIVE_SUBMIT_STEP_2_FAILED,
  REQUEST_SUBMIT_STEP_3,
  RECEIVE_SUBMIT_STEP_3,
  RECEIVE_SUBMIT_STEP_3_FAILED,
  REQUEST_SUBMIT_UPSELL_STEP_1,
  RECEIVE_SUBMIT_UPSELL_STEP_1,
  RECEIVE_SUBMIT_UPSELL_STEP_1_FAILED,
  REQUEST_SUBMIT_UPSELL_STEP_2,
  RECEIVE_SUBMIT_UPSELL_STEP_2,
  RECEIVE_SUBMIT_UPSELL_STEP_2_FAILED,
  REQUEST_SUBMIT_UPSELL_STEP_3,
  RECEIVE_SUBMIT_UPSELL_STEP_3,
  RECEIVE_SUBMIT_UPSELL_STEP_3_FAILED,
  GO_TO_STEP,
  GO_TO_STEP_UPSELL,
  INC_DEVICE_TOTAL,
  DEC_DEVICE_TOTAL,
  RESET_DEVICE_TOTAL,
  FINISH_REGISTRATION,
  SET_PAYMENT_ACTION, INC_DEVICE_TOTAL_UPSELL, DEC_DEVICE_TOTAL_UPSELL, SET_PREV_STEP_UPSELL, SET_PREV_STEP,
} from './types'

import hash from 'object-hash'
import md5 from 'md5'

import {
  MESSAGE_SUCCESS,
  GOPAY_RETURN_URL,
  GOPAY_NOTIFY_URL,
  MESSAGE_ERROR,
} from '../conf/constants'

import { initialize, SubmissionError, change } from 'redux-form'

import { instance } from '../conf/axios'
import { isGTMEnabled } from '../utils/functions'
import TagManager from 'react-gtm-module'
import {requestLogout} from "./login";

export function reinitSmartcard(contract_id, smartcard_id) {
  return function (dispatch, getState) {
    return instance
      .put(`customer/contract/${contract_id}/smart-card/${smartcard_id}`)
      .then((response) => {
        dispatch({
          type: SET_MESSAGE,
          payload: 'Reaktivace karty byla uspěšně provedena.',
          kind: MESSAGE_SUCCESS,
        })
        dispatch({
          type: REACTIVATE_CARD_SUCCESS,
          contract_id: contract_id,
          smartcard_id: smartcard_id,
        })
        return Promise.resolve(response)
      })
      .catch((error) => Promise.reject(error))
  }
}

// const toCheckboxInitialValues = (input = {}) => {
//   let output = {}

//   Object.keys(input).map((key) => {
//     const row = input[key]

//     if (false === key in output) {
//       output[key] = {}
//     }

//     if (row instanceof Array) {
//       row.map((entry) => {
//         if ('base' === key && true === entry.selected) {
//           output[key] = entry.id
//         } else if ('base' !== key) {
//           output[key][entry.id] = entry.selected
//         }
//       })
//     } else if (row instanceof Object) {
//       output[key] = toCheckboxInitialValues(input[key])
//     }
//   })

//   return output
// }

export function initServiceActivationForm(values) {
  return function (dispatch, getState) {
    dispatch(initialize('service_activation_form', values, false))
  }
}

export function initUpsellform(contract_id, data) {
  return function (dispatch) {
    const initialValues = {
      package_base: (
        (data.packages && data.packages.base.find((item) => item.selected)) ||
        {}
      ).id,
      package_other:
        data.packages &&
        data.packages.other.reduce((acc, curr) => {
          if (curr.selected) {
            acc[curr.id] = true
          }
          return acc
        }, {}),
      bounding:
        data.bounding &&
        data.bounding.reduce((acc, curr) => {
          if (curr.selected) {
            acc[curr.id] = true
          }
          return acc
        }, {}),
    }
    dispatch(initialize('service_upsell_form', initialValues))
    dispatch({
      type: RECEIVE_UPSELL,
      contract_id: contract_id,
      payload: data,
    })
  }
}

export function requestContractUpsell(contract_id, values) {
  return function (dispatch, getState) {
    let requestData = {
      packages: [],
      bounding: [],
    }

    if (values) {
      Object.keys(values.package_other) &&
        Object.keys(values.package_other).forEach((key) => {
          requestData.packages.push(key)
        })

      values.package_base && requestData.packages.push(values.package_base)
    }

    dispatch({
      type: REQUEST_UPSELL,
      contract_id: contract_id,
    })

    return instance
      .post(`customer/contract/${contract_id}/upsell`, requestData)
      .then((response) => {
        initUpsellform(contract_id, response.data)(dispatch)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch({
          type: RECEIVE_UPSELL_FAILED,
          contract_id: contract_id,
        })

        return Promise.reject(error)
      })
  }
}

export function requestContractUpsellStep1() {
  return function (dispatch, getState) {

    const state = getState()
    let formValues = state.form.service_upsell_form_u.values

    const selectedInstallation = state.contract_upsell.selectedInstallation
    const deviceTotal = state.contract_upsell.deviceTotal
    // formValues.portfolio = selectedPortfolio
    const currentFormHash = state.contract_upsell.hash1
    const newFormHash = formValues ? hash(formValues) : null

    // const deviceTotal = state.contract_new.deviceTotal
    const options = state.contract_upsell.options.packages
    const deviceMin = options.deviceMin
    const requiredDevices = Object.values(options.device).filter(
      (d) => d.required
    )
    const selectedDevices =
      formValues && formValues.device
        ? Object.keys(formValues.device)
        : []

    let deviceErrors = []
    requiredDevices.forEach((d) => {
      if (
        selectedDevices &&
        (!selectedDevices.includes(d.group) ||
          formValues.device[d.group] < 1)
      ) {
        deviceErrors.push({
          [`device_${d.group}`]: `Musíte si vybrat alespoň ${deviceMin} zařízení označené hvězdičkou.`,
        })
      }
    })
    // console.log(requiredDevices)
    // console.log(deviceErrors)
    if (options.device.length > 0 && deviceMin > 0 && deviceErrors.length >= requiredDevices.length) {
      throw new SubmissionError({
        device_e: `Musíte si vybrat alespoň ${deviceMin} zařízení označené hvězdičkou.`,
        _error: 'No required device selected',
      })
    }

    const deviceTotalFromForm = formValues.device ? Object.values(formValues.device) : []
    let deviceTotalFromFormCount
    if (deviceTotalFromForm.length > 0) {
      deviceTotalFromFormCount = deviceTotalFromForm.reduce((x, y) => x + y)
    }
    if (deviceTotalFromFormCount > 0 && !selectedInstallation) {
      throw new SubmissionError({
        installation_type_e: `Musíte si zvolit způsob instalace.`,
        _error: 'No installation type selected',
      })
    }

    if (currentFormHash === newFormHash && currentFormHash !== null) {
      return Promise.resolve()
    }

    let packages = []
    let devices = []
    const packageCoreActive = Object.values(options.core).filter(
      (core) => core.active
    )

    // VALIDACE
    if (!formValues.package_core && !packageCoreActive.length) {
      window.scrollTo(0, 0)
      throw new SubmissionError({
        package_core: 'Nebyl zvolen balíček',
        _error: 'No core package selected',
      })
    }

    try {
      if (formValues.package_core !== null && formValues.package_core !== undefined) {
        packages.push(formValues.package_core)
      } else {
        if (packageCoreActive.length > 0) {
          packageCoreActive.map((core) =>
            packages.push(core.group)
          )
        }
      }
    } catch (err) {
      if (packageCoreActive.length > 0) {
        packageCoreActive.map((core) =>
          packages.push(core.group)
        )
      }
    }
    console.log(packages);

    try {
      packages.push(
        ...Object.entries(formValues.package_suppl)
          .filter((p) => p[1])
          .map((p) => p[0])
      )
    } catch (err) {}

    try {
      packages.push(
        ...Object.entries(formValues.package_other)
          .filter((p) => p[1])
          .map((p) => p[0])
      )
    } catch (err) {}

    try {
      if (formValues.device !== null && formValues.device !== undefined) {
        devices.push(
          ...Object.entries(formValues.device)
            .filter((d) => d[1] > 0)
            .map((d) => ({
              device: d[0],
              count: d[1],
            }))
        );
      }

    } catch (err) {}

    // data pro request
    const step1Payload = {
      // portfolio: selectedPortfolio,
      packages: packages,
      //TODO: Tady to ale nejde natvrdo, přece
      installation: selectedInstallation ? selectedInstallation : 1,
      devices: devices,
    }

    dispatch({
      type: REQUEST_SUBMIT_UPSELL_STEP_1,
      formData: formValues,
      formHash: newFormHash,
      step1Payload,
    })

    return dispatch(sendUpsellStep1(step1Payload))
  }
}

export function sendUpsellStep1(step1Payload) {
  return function (dispatch, getState) {
    const contract_id = getState().contract_nr.items[
      getState().contract_nr.selected
      ].map((cID) => cID)
    return instance
      .post(`customer/contract/${contract_id}/upsell/step-1`, step1Payload)
      .then((res) => {
        dispatch({
          type: RECEIVE_SUBMIT_UPSELL_STEP_1,
          payload: res.data,
        })
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        dispatch({
          type: RECEIVE_SUBMIT_UPSELL_STEP_1_FAILED,
        })
        return Promise.reject(error)
      })
  }
}

export function requestContractUpsellStep2() {
  return function (dispatch, getState) {
    // const state = getState()
    const state = getState().contract_upsell.overview
    // const currentFormHash = getState().contract_upsell.hash2
    // const newFormHash = hash(values)

    // if (currentFormHash === newFormHash && currentFormHash !== null) {
    //   return Promise.resolve()
    // }

    const step2Payload = {
      session: state.session,
      // birthDate: values.birthDate,
      // address: values.address,
      // installAddress: values.installAddress,
      // invoiceAddress: values.invoiceAddress,
      // invoiceType: values.invoiceType,
      // invoiceTypeValue: values.invoiceTypeValue,
      // invoiceDelivery: values.invoiceDelivery,
    }

    return dispatch(sendUpsellStep2(step2Payload))
  }
}

export function sendUpsellStep2(step2Payload) {
  return function (dispatch, getState) {
    dispatch({
      type: REQUEST_SUBMIT_UPSELL_STEP_2,
      // formHash: newFormHash,
      step2Payload,
    })
    const contract_id = getState().contract_nr.items[
      getState().contract_nr.selected
      ].map((cID) => cID)
    return instance
      .post(`customer/contract/${contract_id}/upsell/step-2`, step2Payload)
      .then((res) => {
        dispatch({
          type: RECEIVE_SUBMIT_UPSELL_STEP_2,
          payload: res.data,
        })
        console.log(res.data)
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        if (error.response.data.message === 'Not Found') {
          // pri pouzitem tokenu znovu odesleme step 1 pro ziskani noveho tokenu
          return dispatch(
            sendUpsellStep2(getState().contract_upsell.step1Payload)
          ).then(() => dispatch(sendUpsellStep2(step2Payload)))
        } else {
          dispatch({
            type: RECEIVE_SUBMIT_UPSELL_STEP_2_FAILED,
          })
          return Promise.reject(error)
        }
      })
  }
}

export function requestContractUpsellStep3(values) {
  return async function (dispatch, getState) {
    dispatch({
      type: REQUEST_SUBMIT_UPSELL_STEP_3,
    })
    const contract_id = getState().contract_nr.items[
      getState().contract_nr.selected
    ].map((cID) => cID)
    const state = getState().contract_upsell.overview
    const acks = Object.entries(
      getState().form.service_upsell_contract_form_u.values.acknowledgments
    ).map((d) => ({ id: d[0], confirmed: d[1] }))

    const step3Payload = {
      session: state.session,
      acknowledgments: acks,
      password: values.password,
      // returnUrl: GOPAY_RETURN_URL,
      // notificationUrl: GOPAY_NOTIFY_URL,
    }

    try {
      const res = await instance.post(
        `customer/contract/${contract_id}/upsell/step-3`,
        step3Payload
      )

      if (!res.data.gate_url || !res.data.midas_id) {
        // TODO: error
      }

      dispatch({
        type: RECEIVE_SUBMIT_UPSELL_STEP_3,
        payload: res.data,
      })
      return res.data
    } catch (error) {
      console.log('catch request')
      // if(error.response.status === 401) {
      //
      // }
      if (error.response.data.message === 'Not Found') {
        // pri pouzitem tokenu znovu odesleme step 1 pro ziskani noveho tokenu
        return dispatch(sendUpsellStep1(getState().contract_upsell.step1Payload))
          .then(() => dispatch(sendUpsellStep2(getState().contract_upsell.step2Payload)))
          .then(() => dispatch(requestContractUpsellStep3(values)))
      } else {
        dispatch({
          type: RECEIVE_SUBMIT_UPSELL_STEP_3_FAILED,
        })
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        if(error.response.status === 500) {
          requestLogout()
          window.location.assign('/')
        }
        return dispatch({type: GO_TO_STEP_UPSELL, payload: 3})
       // return error
      }
    }
  }
}

export function requestNewServicePortfolios() {
  return async function (dispatch, getState) {
    dispatch({
      type: REQUEST_NEW_SERVICE_PORTFOLIOS,
    })
    const sp = getState().contract_new.selectedPortfolio
    try {
      const res = await instance.get('customer/new-service/portfolio')
      dispatch({
        type: RECEIVE_NEW_SERVICE_PORTFOLIOS,
        payload: res.data.data,
      })
      if (sp === null && res.data.data.length > 0) {
        dispatch({
          type: SELECT_PORTFOLIO,
          payload: res.data.data[0].id,
        })
      }
      return Promise.resolve(res.data.data)
    } catch (error) {
      return await Promise.reject(error)
    }
  }
}

export function requestNewServiceOptions(values) {
  return function (dispatch, getState) {
    let packages = []
    const selectedPortfolio = getState().contract_new.selectedPortfolio
    const option = getState().contract_new.options[selectedPortfolio]

    // pridani defaultniho MULTISCREENu z portfolia k requestu
    try {
      Object.values(option.other)
        .filter((o) => !o.private)
        .filter((o) => o.required)
        .filter((o) => o.selected)
        .forEach((o) => {
          if (!values.packages.includes(o.group)) packages.push(o.group)
        })
    } catch (error) {}

    values.packages = [...values.packages, ...packages]

    dispatch({
      type: REQUEST_NEW_SERVICE_OPTIONS,
    })
    return instance
      .options('customer/new-service/option', { params: values })
      .then((res) => {
        dispatch({
          type: RECEIVE_NEW_SERVICE_OPTIONS,
          payload: { [values.portfolio]: res.data },
        })

        try {
          const defaultCorePackage = Object.values(res.data.core).find((o) => o.selected)
          if (defaultCorePackage) {
            dispatch(
              change(
                'service_upsell_form',
                'package_core',
                defaultCorePackage.group
              )
            )
          }
        } catch (error) {}
        
        try {
          Object.values(res.data.other)
            .filter((o) => !o.private)
            .forEach((o) => {
              dispatch(
                change(
                  'service_upsell_form',
                  `package_other[${o.group}]`,
                  o.selected
                )
              )
            })
        } catch (error) {}

        const defaultInstallation = Object.values(res.data.installation).filter(
          (i) => i.selected
        )

        if (
          getState().contract_new.selectedInstallation === null &&
          defaultInstallation.length > 0
        ) {
          dispatch({
            type: SELECT_INSTALLATION_NEW,
            payload: defaultInstallation[0].id,
          })
        }

        return Promise.resolve(res.data)
      })
      .catch(() => {
        dispatch({
          type: REQUEST_NEW_SERVICE_OPTIONS_FAILED,
        })
      })
  }
}

export function requestUpsellOptions(values, form) {
  return async function (dispatch, getState) {
    let packages = []
    const selectedPortfolio = getState().contract_new.selectedPortfolio
    const option = getState().contract_new.options[selectedPortfolio]
    const contract_id = getState().contract_nr.items[
      getState().current_nr.value
    ]




    // pridani defaultniho MULTISCREENu z portfolia k requestu
    try {
      Object.values(option.other)
        .filter((o) => !o.private)
        .filter((o) => o.required)
        .filter((o) => o.selected)
        .forEach((o) => {
          if (!values.packages.includes(o.group)) packages.push(o.group)
        })
    } catch (error) {}
    const formDevices = (form && form.device) && Object.values(form.device)
    let formTotalCount = 0
    if(formDevices && formDevices.length > 0) {
      formTotalCount = formDevices.reduce((a, b) => a + b)
    }

    values.packages = [...values.packages, ...packages]

    dispatch({
      type: REQUEST_UPSELL_OPTIONS,
    })

    try {
      const res = await instance.options(
        `customer/contract/${contract_id}/upsell/option`,

        { params: values }
      )

      dispatch({
        type: RECEIVE_UPSELL_OPTIONS,
        payload: res.data,
        formTotalCount: formTotalCount
      })

      try {
        Object.values(res.data.other)
          .filter((o) => !o.private)
          .forEach((o) => {
            dispatch(
              change(
                'service_upsell_form',
                `package_other[${o.group}]`,
                o.selected
              )
            )
          })
      } catch (error) {}

      const defaultInstallation = Object.values(res.data.packages.installation).filter(
        (i) => i.selected
      )

      if (
        getState().contract_upsell.selectedInstallation === null &&
        defaultInstallation.length > 0
      ) {
        dispatch({
          type: SELECT_INSTALLATION_UPSELL,
          payload: defaultInstallation[0].id,
        })
      }

      return Promise.resolve(res.data)
    } catch (error) {
      dispatch({
        type: REQUEST_UPSELL_OPTIONS_FAILED,
      })
    }
  }
}

export function requestNewServices(force = false) {
  return function (dispatch, getState) {
    const lastUpdated = getState().contract_new.lastUpdated
    const diff = new Date().getTime() - new Date(lastUpdated).getTime()
    // const packageCore = getState().contract_new.selectedPackageCore

    // pokud jsme stahli nabidku behem 3 minut, nestahujeme znovu
    // console.log(`last change: ${diff}`)

    if (!force && diff < 60 * 60 * 60 * 3) {
      const selectedPortfolio = getState().contract_new.selectedPortfolio
      const options = getState().contract_new.options[selectedPortfolio]
      return Promise.resolve(options)
    }
    
    return dispatch(requestNewServicePortfolios())
      .then((data) => {
        data.forEach((portfolio) => {
          // const portfolio = data[0]
          dispatch(
            requestNewServiceOptions({
              portfolio: portfolio.id,
              packages: ['MEDIUM','MULTISCREEN'] // defaultni balicky
            })
          )
        })
      })
      .catch((error) => Promise.reject(error))
  }
}

export function requestUpsell(force = false) {
  return function (dispatch, getState) {
    const lastUpdated = getState().contract_upsell.lastUpdated
    const diff = new Date().getTime() - new Date(lastUpdated).getTime()
    const packageCore = getState().contract_upsell.selectedPackageCore
    // pokud jsme stahli nabidku behem 3 minut, nestahujeme znovu
    // console.log(`last change: ${diff}`)

    if (!force && diff < 60 * 60 * 60 * 3) {
      // const selectedPortfolio = getState().contract_upsell.selectedPortfolio
      const options = getState().contract_upsell.options
      return Promise.resolve(options)
    }

    return dispatch(
      requestUpsellOptions({
        packages: [packageCore],
      })
    )
  }
}

export function selectPortfolio(portfolio) {
  return function (dispatch, getState) {
    const form = getState().form
    let deviceTotal = 0
    try {
      deviceTotal = Object.values(
        form.service_upsell_form.values.device[portfolio]
      ).reduce((a, b) => a + b)
    } catch (err) {
      deviceTotal = 0
    }
    return dispatch({
      type: SELECT_PORTFOLIO,
      payload: portfolio,
      deviceTotal: deviceTotal,
    })
  }
}

export function selectPackageCore(packageCore) {
  return function (dispatch) {
    return dispatch({
      type: SELECT_PACKAGE_CORE,
      payload: packageCore,
    })
  }
}

export function selectInstallationNew(installation) {
  return function (dispatch) {
    return dispatch({
      type: SELECT_INSTALLATION_NEW,
      payload: installation,
    })
  }
}
export function selectInstallationUpsell(installation) {
  return function (dispatch) {
    return dispatch({
      type: SELECT_INSTALLATION_UPSELL,
      payload: installation,
    })
  }
}

export function goToStep(step) {
  return function (dispatch, getState) {
    if (isGTMEnabled()) {
      const { user } = getState()
      let dl = {
        dataLayer: {
          event: 'ga.pageview',
          page: {
            title: 'Objednávka balíčku - Krok ' + step,
            path:
              window.location.pathname +
              `${
                window.location.pathname[
                  window.location.pathname.length - 1
                ] === '/'
                  ? ''
                  : '/'
              }${step}`,
            section: 'Zona',
          },
        },
      }
      if (user.auth) {
        dl.dataLayer.visitor = {
          id: user.id,
          email: user.email,
          hashedEmail: md5(user.email),
          type: 'customer',
        }
      }

      TagManager.dataLayer(dl)
    }
    window.scrollTo(0, 0)
    return dispatch({
      type: GO_TO_STEP,
      payload: step,
    })
  }
}

export function goToStepUpsell(step) {
  return function (dispatch) {
    window.scrollTo(0, 0)
    return dispatch({
      type: GO_TO_STEP_UPSELL,
      payload: step,
    })
  }
}

export function setPrevStepUpsell(step) {
  return function (dispatch) {
    return dispatch({
      type: SET_PREV_STEP_UPSELL,
      payload: step
    })
  }
}

export function resetDeviceTotal(val = 0) {
  return function (dispatch, getState) {
    return dispatch({
      type: RESET_DEVICE_TOTAL,
      payload: val,
    })
  }
}

export function incDeviceTotal() {
  return function (dispatch, getState) {
    return dispatch({
      type: INC_DEVICE_TOTAL,
    })
  }
}

export function decDeviceTotal() {
  return function (dispatch) {
    return dispatch({
      type: DEC_DEVICE_TOTAL,
    })
  }
}

export function incDeviceTotalUpsell() {
  return function (dispatch) {
    return dispatch({
      type: INC_DEVICE_TOTAL_UPSELL,
    })
  }
}

export function decDeviceTotalUpsell() {
  return function (dispatch) {
    return dispatch({
      type: DEC_DEVICE_TOTAL_UPSELL,
    })
  }
}

export function finishRegistration() {
  return function (dispatch) {
    dispatch({
      type: FINISH_REGISTRATION,
    })
  }
}

export function requestSubmitStep1() {
  return function (dispatch, getState) {
    let formValues = getState().form.service_upsell_form.values
    const selectedPortfolio = getState().contract_new.selectedPortfolio
    formValues.portfolio = selectedPortfolio
    const currentFormHash = getState().contract_new.hash1
    const newFormHash = formValues ? hash(formValues) : null

    // VALIDACE
    try {
      if (!formValues.package_core) {
        window.scrollTo(0, 0)
        throw new SubmissionError({
          package_core: 'Nebyl zvolen balíček',
          _error: 'No core package selected',
        })
      }
    } catch (error) {
      window.scrollTo(0, 0)
      throw new SubmissionError({
        package_core: 'Nebyl zvolen balíček',
        _error: 'No core package selected',
      })
    }

    // const deviceTotal = getState().contract_new.deviceTotal
    const options = getState().contract_new.options[selectedPortfolio]
    const deviceMin = options.deviceMin
    const requiredDevices = Object.values(options.device).filter(
      (d) => d.required
    )
    const selectedDevices =
      formValues && formValues.device && formValues.device[selectedPortfolio]
        ? Object.keys(formValues.device[selectedPortfolio])
        : []

    let deviceErrors = []
    requiredDevices.forEach((d) => {
      if (
        selectedDevices &&
        (!selectedDevices.includes(d.group) ||
          formValues.device[selectedPortfolio][d.group] < 1)
      ) {
        deviceErrors.push({
          [`device_${d.group}`]: `Musíte si vybrat alespoň ${deviceMin} zařízení označené hvězdičkou.`,
        })
      }
    })
    // console.log(requiredDevices)
    // console.log(deviceErrors)

    if (deviceMin > 0 && deviceErrors.length >= requiredDevices.length) {
      throw new SubmissionError({
        device_e: `Musíte si vybrat alespoň ${deviceMin} zařízení označené hvězdičkou.`,
        _error: 'No required device selected',
      })
    }

    if (currentFormHash === newFormHash && currentFormHash !== null) {
      return Promise.resolve()
    }

    let packages = []
    let devices = []

    try {
      packages.push(formValues.package_core)
    } catch (err) {}

    try {
      packages.push(
        ...Object.entries(formValues.package_suppl)
          .filter((p) => p[1])
          .map((p) => p[0])
      )
    } catch (err) {}

    try {
      packages.push(
        ...Object.entries(formValues.package_other)
          .filter((p) => p[1])
          .map((p) => p[0])
      )
    } catch (err) {}

    try {
      devices.push(
        ...Object.entries(formValues.device[selectedPortfolio])
          .filter((d) => d[1] > 0)
          .map((d) => ({
            device: d[0],
            count: d[1],
          }))
      )
    } catch (err) {}

    // data pro request
    const step1Payload = {
      portfolio: selectedPortfolio,
      packages: packages,
      installation: getState().contract_new.selectedInstallation,
      devices: devices,
    }

    dispatch({
      type: REQUEST_SUBMIT_STEP_1,
      formData: formValues,
      formHash: newFormHash,
      step1Payload,
    })

    return dispatch(sendNewServiceStep1(step1Payload))
  }
}

function sendNewServiceStep1(step1Payload) {
  return function (dispatch) {
    return instance
      .post('customer/new-service/step-1', step1Payload)
      .then((res) => {
        dispatch({
          type: RECEIVE_SUBMIT_STEP_1,
          payload: res.data,
        })
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        dispatch({
          type: RECEIVE_SUBMIT_STEP_1_FAILED,
        })
        return Promise.reject(error)
      })
  }
}

export function requestSubmitStep2(values) {
  return function (dispatch, getState) {
    const state = getState().contract_new.overview
    const currentFormHash = getState().contract_new.hash2
    const newFormHash = hash(values)

    if (currentFormHash === newFormHash && currentFormHash !== null) {
      return Promise.resolve()
    }

    const step2Payload = {
      session: state.session,
      birthDate: values.birthDate,
      address: values.address,
      installAddress: values.installAddress,
      invoiceAddress: values.invoiceAddress,
      invoiceType: values.invoiceType,
      invoiceTypeValue: values.invoiceTypeValue,
      invoiceDelivery: values.invoiceDelivery,
    }

    dispatch({
      type: REQUEST_SUBMIT_STEP_2,
      formHash: newFormHash,
      step2Payload,
    })

    return instance
      .post('customer/new-service/step-2', step2Payload)
      .then((res) => {
        dispatch({
          type: RECEIVE_SUBMIT_STEP_2,
          payload: res.data,
        })
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        if (error.response.data.message === 'Not Found') {
          // pri pouzitem tokenu znovu odesleme step 1 pro ziskani noveho tokenu
          return dispatch(
            sendNewServiceStep1(getState().contract_new.step1Payload)
          ).then(() => dispatch(sendNewServiceStep2(step2Payload)))
        } else {
          dispatch({
            type: RECEIVE_SUBMIT_STEP_2_FAILED,
          })
          return Promise.reject(error)
        }
      })
  }
}

function sendNewServiceStep2(step2Payload) {
  return function (dispatch, getState) {
    dispatch({
      type: REQUEST_SUBMIT_STEP_2,
      step2Payload,
    })
    return instance
      .post(
        'customer/new-service/step-2',
        Object.assign({}, step2Payload, {
          session: getState().contract_new.overview.session,
        })
      )
      .then((res) => {
        dispatch({
          type: RECEIVE_SUBMIT_STEP_2,
          payload: res.data,
        })
        return Promise.resolve(res.data)
      })
      .catch((error) => {
        dispatch({
          type: RECEIVE_SUBMIT_STEP_2_FAILED,
        })
        return Promise.reject(error)
      })
  }
}

export function requestSubmitStep3(values) {
  return async function (dispatch, getState) {
    dispatch({
      type: REQUEST_SUBMIT_STEP_3,
    })
    const state = getState().contract_new.overview
    const acks = Object.entries(
      getState().form.service_upsell_contract_form.values.acknowledgments
    ).map((d) => ({ id: d[0], confirmed: d[1] }))

    const step3Payload = {
      session: state.session,
      acknowledgments: acks,
      password: values.password,
      returnUrl: GOPAY_RETURN_URL,
      notificationUrl: GOPAY_NOTIFY_URL,
    }

    try {
      const res = await instance.post(
        'customer/new-service/step-3',
        step3Payload
      )

      if (!res.data.gate_url || !res.data.midas_id) {
        // TODO: error
      }

      dispatch({
        type: RECEIVE_SUBMIT_STEP_3,
        payload: res.data,
      })
      return res.data
    } catch (error) {
      if (error.response.data.message === 'Not Found') {
        // pri pouzitem tokenu znovu odesleme step 1 pro ziskani noveho tokenu
        return dispatch(sendNewServiceStep1(getState().contract_new.step1Payload))
          .then(() => dispatch(sendNewServiceStep2(getState().contract_new.step2Payload)))
          .then(() => dispatch(requestSubmitStep3(values)))
      } else {
        dispatch({
          type: RECEIVE_SUBMIT_STEP_3_FAILED,
        })
        dispatch({
          type: SET_MESSAGE,
          payload: error,
          kind: MESSAGE_ERROR,
        })
        if(error.response.status === 500) {
          requestLogout()
          window.location.assign('/')
        }
        throw error
      }
    }
  }
}

export function setPaymentAction(action) {
  return function (dispatch, getState) {
    dispatch({
      type: SET_PAYMENT_ACTION,
      action,
    })
  }
}
